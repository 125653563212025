import axios from "@/plugins/http";
let apiUrl = "api/reports";

export default {
  GetReports() {
    return axios.get(apiUrl);
  },
  GetReportDetails(id) {
    return axios.get(`${apiUrl}/${id}`);
  },
};
