<template>
  <!-- Tile -->
  <div v-if="display === displayListTypes.CARDS" class="tile-wrapper">
    <v-card
      class="tile"
      flat
      :height="isMobile ? 'auto' : 160"
      :min-width="isMobile ? 0 : 430"
      @click="$emit('click', 'card')"
    >
      <v-card-text class="textColor--text">
        <div class="header">
          {{ name }}
        </div>
        <v-divider />
        <div class="description">
          {{ description }}
        </div>
      </v-card-text>
    </v-card>
  </div>
  <!-- Inline -->
  <div v-else class="inline-wrapper">
    <v-card flat class="inline" @click="$emit('click', 'card')">
      <v-card-text class="textColor--text">
        <div class="header">
          {{ name }}
        </div>
        <div class="description">
          {{ description }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DisplayListTypes from "@/models/displayListTypes.js";
import { mapGetters } from "vuex";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    display: {
      type: String,
      default: DisplayListTypes.CARDS,
    },
  },
  data() {
    return {
      displayListTypes: DisplayListTypes,
    };
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
  },
};
</script>

<style scoped lang="scss">
.tile-wrapper,
.inline-wrapper {
  box-shadow: $card-shadow;
}

.tile:hover,
.inline:hover {
  background: $card-hover;
}

.tile,
.inline {
  .header {
    color: $text-color;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .description {
    margin-top: 10px;
  }
}
</style>
