<template>
  <DataGrid
    :minimumCardWidth="426"
    :items="displayReports"
    :card="card"
    :display.sync="display"
    :isLoading="isLoading"
    @click="showDetails"
    @update:search="searchHandler"
    :pagination="{}"
  >
    <template #toolbar>
      <v-spacer></v-spacer>
    </template>
  </DataGrid>
</template>

<script>
import ReportService from "@/services/ReportService";
import ReportCard from "@/components/Reports/ReportCard";
import DataGrid from "@/components/Shared/DataGrid";
import DisplayListTypes from "@/models/displayListTypes.js";

export default {
  name: "ReportsList",
  components: {
    DataGrid,
  },
  created() {
    this.fetchReports();
  },
  data() {
    return {
      reports: [],
      displayReports: [],
      display: DisplayListTypes.CARDS,
      card: ReportCard,
      isLoading: false,
    };
  },
  methods: {
    fetchReports() {
      this.isLoading = true;
      ReportService.GetReports()
        .then((res) => {
          this.reports = res;
          this.displayReports = res;
        })
        .finally(() => (this.isLoading = false));
    },
    showDetails(report) {
      this.$router.push({ name: "reportDetails", params: { id: report.id } });
    },
    searchHandler(searchValue) {
      this.displayReports = searchValue
        ? this.reports.filter((item) =>
            item.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        : this.reports;
    },
  },
};
</script>
